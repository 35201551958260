import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
// import Image from "../components/image";
import SEO from "../components/seo";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import feature from "../images/feature.jpg";
// import paradeOpt from "../images/parade-opt.jpg";
import edenFloorPlan from "../images/eden-floor-plan.jpg";
import commImg from "../images/comm-small.png";
import diningOpt from "../images/dining-1-opt.jpg";
import botyOpt from "../images/boty-opt.jpg";
import paradeAwardsOpt from "../images/parade-awards-opt.jpg";
import sealImg from "../images/seal.png";
import "../css/home.css";
import { FaCrown, FaTrophy, FaFacebookSquare, FaPaperPlane, FaHouseDamage, FaCheckCircle } from 'react-icons/fa';

const IndexPage = () => (<Layout>
  <SEO title="Home"/>

  <div>
    <div className="container-fluid px-0">
      <div className="row">
        <div className="col-sm">
          <img src={feature} className="img-fluid" alt="Heritage Craft Homes"/>
          <h3 className="over-img-title d-sm-none">Quality Custom Homes for over 20 Years</h3>
          <h1 className="over-img-title d-none d-md-block">Quality Custom Homes for over 20 Years</h1>
        </div>
      </div>
    </div>
    <div className="container mt-4">
      <div className="row">
        <div className="col-sm text-center">

          <h6>For 20 years Heritage Craft Homes has earned a reputation as a luxury home builder in Northern Utah, known for providing quality craftsmanship, sound product, and superior customer service in Weber, Davis, and Box Elder Counties.</h6>

          <Row className="mt-2">
            <Col className="text-center">
              <Link to="about-us"><button type="button" className="btn btn-success btn-lg mt-3">READ MORE ABOUT US</button></Link>
            </Col>
          </Row>

        </div>
      </div>
      <div className="row home-feat-row">
        <Link className="col-sm text-center mb-5" to="plans">
          <img src={edenFloorPlan} className="home-option img-fluid" alt="House Plans"/>
          <h4 className="mt-2 theme-color">House Plans</h4>
          <p>Choose a plan, keep it as is, or modify it as much as you like.</p>
        </Link>
        <div className="col-sm"/>
        <Link className="col-sm text-center mb-5" to="communities">
          <img src={commImg} className="home-option img-fluid" alt="Communities"/>
          <h4 className="mt-2 theme-color">Communities</h4>
          <p>Build on our lot, or yours!</p>
        </Link>
        <div className="col-sm"/>
        <Link className="col-sm text-center mb-5" to="gallery">
          <img src={diningOpt} className="home-option img-fluid" alt="Gallery"/>
          <h4 className="mt-2 theme-color">Gallery</h4>
          <p>See our gallery of fine work.</p>
        </Link>
      </div>

    </div>

<div className="container-fluid px-0">

  <Row className="theme-bg">
    <Col md={5} className="text-center d-flex align-items-center justify-content-center">

      <div className="px-2">

        <h2 className="mt-1">2022 Builder of the Year</h2>
        <h4 className="mt-2 mb-2">Northern Wasatch Home Builders Association</h4>

        

        <Link to="about-us">
          <FaTrophy className="icon-lg mt-3 mb-2 theme-color theme-border theme-circle px-3" />
          <div className="mb-2">Learn Why We Are the Builder of the Year</div>
        </Link>

      </div>

    </Col>
    <Col md={7} className="px-0">
      <img src={botyOpt} className="img-fluid" alt="2022 Builder of the Year"/>
    </Col>
  </Row>
</div>

<div className="container-fluid px-0 mb-5">

  <Row className="theme-bg">
    <Col md={7} className="px-0">
      <img src={paradeAwardsOpt} className="img-fluid" alt="Awards"/>
    </Col>
    <Col md={5} className="text-center d-flex align-items-center justify-content-center">

      <div className="px-2">

        <h2 className="mt-1">Award Winning Experience</h2>
        <h4 className="mt-2 mb-2">Parade of Homes Awards</h4>

        <div><FaTrophy /> Best of Wasatch Overall Winner</div>

        <div><FaCrown /> Best In Show Award</div>
        <div><FaTrophy /> Best Master Suite Award</div>
        <div><FaTrophy /> Best Kitchen Award</div>
        <div><FaTrophy /> Best Special Feature Award</div>
        <div><FaTrophy /> Best Curb Appeal Award</div>
        <div><FaTrophy /> Best Landscaping Award</div>
        <div><FaTrophy /> Best Floor Plan Award</div>
        <div><FaTrophy /> Best Craftsmanship Award</div>

        <Link to="parade">
          <FaCrown className="icon-lg mt-3 mb-2 theme-color theme-border theme-circle px-3" />
          <div className="mb-2">VIEW OUR PARADE HOMES</div>
        </Link>

      </div>

    </Col>
  </Row>
</div>

<Container className="mt-5 mb-5">
  <Row>
    <Col className="text-center">

      <h1>Our Mission</h1>

      <Row className="mt-5">
        <h6>
At Heritage Craft Homes, we are committed to building high-quality residential properties that stand the test of time. Our mission is to provide our clients with exceptional craftsmanship, attention to detail, and personalized service. We strive to create homes that not only meet our clients' needs but exceed their expectations. With a focus on sustainability and innovation, we aim to build homes that are not only beautiful but also environmentally responsible. At Heritage Craft Homes, we are dedicated to creating homes that our clients will love for years to come.</h6>
      </Row>


    </Col>
  </Row>
</Container>

<Container className="mt-5 mb-5">
  <Row>
    <Col className="text-center">

      <h1>Committment</h1>

      <Row className="mt-5">

        <Col className="text-center">
          <h3 className="dark-header">We Stand By Our Work</h3>
          <img src={sealImg} alt="Guarantee"/>
          <h4 className="mt-4 mb-4"><FaHouseDamage /> Licensed and Insured</h4>


          <hr className="d-sm-none mt-4 mb-4" />
          {/*}<h4 className="d-none d-md-block">~ Quality Craftsmanship, Sound Product, Superior Customer Service ~</h4>*/}

        </Col>

        <Col className="text-center">
          <h3 className="dark-header">We Stand Out From the Rest</h3>

          <h6 className="mt-4">We truly care about our customers and<br />give personal attention to each one of them.</h6>
          <div>
            <div>~ Integrity and honesty from top to bottom.</div>
            <div>~ The best materials and labor in every project.</div>
            <div>~ Affordable and fair prices.</div>
            <div>~ We earn our reputation on each and every job.</div>
            <div>~ Serving you is our desire; pleasing you is our commitment.</div>
          </div>
          <FaCheckCircle className="mt-4 mb-3 theme-color icon-lg" />
        </Col>
      </Row>


    </Col>
  </Row>
</Container>

    <div className="container-fluid px-0">
      <Row className="theme-bg contact-row">
        <Col className="text-center">

          <h1 className="mt-4">Get in Touch</h1>

          <Row className="mt-4">
            <Col className="text-center">
              <Link to="contact-us">
                <FaPaperPlane className="mt-3 mb-3 theme-color icon-lg" />
                <h4>Send us a Message</h4>
              </Link>
            </Col>
            <Col className="text-center">
              <a href="https://www.facebook.com/HeritageCraftRealEstate/">
                <FaFacebookSquare className="mt-3 mb-3 theme-color icon-lg" />
                <h4>Follow us on Facebook</h4>
              </a>
            </Col>
          </Row>

        </Col>
      </Row>
    </div>


</div>

</Layout>)

export default IndexPage
